import { StructuredText } from 'apps/website/src/components/StructuredText';
import { isEmptyDocument } from 'datocms-structured-text-utils';
import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { Popup } from 'libs/growth-platform-brand-system-v2/src/components/Popup';
import { Pricing } from 'libs/growth-platform-brand-system-v2/src/components/Pricing';
import { PricingCard } from 'libs/growth-platform-brand-system-v2/src/components/PricingCard';
import { textCaption } from 'libs/growth-platform-brand-system-v2/src/styles/typography';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import React, { useContext } from 'react';

import { BrandSystemContext } from 'libs/growth-platform-brand-system-v2/src/contexts/BrandSystemContext';
import { useParams } from 'libs/growth-platform-brand-system-v2/src/utils/useParams';
import { LocaleType, useCurrency } from 'apps/website/src/context/Currency';
import { prepareCta } from '../lib/utils';
import { PriceOnDemand } from './PriceOnDemand';

import { HighlightedBorder } from 'libs/growth-platform-brand-system-v2/src/components/PricingCard/HighlightedBorder';
import Info from 'libs/growth-platform-brand-system-v2/src/foundations/pictograms/info_outline.svg';
import { useCalculatePrice } from '../lib/useCalculatePrice';

export const Card = ({
  isDisabled = false,
  cta,
  displayEmptyLines,
  locale,
  template,
  prefixPricePerEmployee,
  index = 0,
  previousOffer, // See comment below (useCalculatePrice l.40)
  offer,
  firstPayrollOffer, // deprecated
  hideTotalPrice,
  installationLabel,
  ...offerDetails
}: any) => {
  const [params] = useParams();

  /****** useCalculatePrice
   * In Discount display mode, the previousOffer is the offer displayed before the discount.
   * In Regular mode, the previousOffer is undefined, so we display the offer.
   */
  const { offerPriceTotal, firstPayrollPriceTotal, isFirstPayroll } =
    useCalculatePrice(
      previousOffer ? previousOffer : offer,
      previousOffer ? offer : firstPayrollOffer,
      index,
      params,
    );

  const { language = '' } = useContext(BrandSystemContext);
  const currency = useCurrency(language as LocaleType)?.symbol;

  const offerProps = offer?.getProps();
  const {
    title,
    onDemandTitle,
    onDemandText,
    engagementLabel,
    features,
    featuresNotIncluded,
    price,
    highlightedLabel,
    borderHighlighted,
  } = offerProps;

  const firstPayrollOfferProps = previousOffer
    ? previousOffer?.getProps()
    : firstPayrollOffer?.getProps();
  const {
    highlightedLabel: firstPayrollhighlightedLabel,
    title: firstPayrollTitle,
    price: firstPayrollPrice,
  } = firstPayrollOfferProps || {};

  const {
    prefixTotal,
    sufixTotal,
    buttonDisabledLabel,
    overrideGlobalCta,
    ctaOverride,
    numberCards,
  } = offerDetails;

  const width =
    numberCards && numberCards < 4
      ? 'w-[calc(100vw-40px)] sm:w-[320px] md:w-[400px] min-w-[200px]'
      : 'sm:w-[294px] min-w-[200px]';

  const ctaCard = prepareCta(
    cta,
    isDisabled,
    buttonDisabledLabel,
    overrideGlobalCta,
    ctaOverride,
  );

  const addOns =
    isFirstPayroll && firstPayrollOffer
      ? firstPayrollOffer?.get('addOns')
      : offer?.get('addOns');

  const isFirstPayrollOffer = isFirstPayroll && firstPayrollOffer;

  return (
    <PricingCard
      {...offer}
      isDisabled={isDisabled || false}
      className={classNames(
        'self-stretch justify-between relative mt-[34px]',
        previousOffer ? 'isDiscountOffer' : '',
        width,
      )}
      isPopular={false}
      classNamePricingCard={
        borderHighlighted
          ? 'rounded-t-none border border-solid border-blue-101'
          : ''
      }
    >
      <HighlightedBorder highlightedBorder={borderHighlighted} />
      <div className="flex flex-col flex-1">
        <div className="flex flex-col justify-start">
          <div className="flex items-center justify-between w-full">
            <PricingCard.Header
              title={isFirstPayrollOffer ? firstPayrollTitle : title}
            />
            {!isFirstPayroll && highlightedLabel && (
              <PricingCard.Highlight className="highlight mb-24">
                {highlightedLabel}
              </PricingCard.Highlight>
            )}
            {isFirstPayrollOffer && firstPayrollhighlightedLabel && (
              <PricingCard.Highlight color="mint" className="mb-24">
                {firstPayrollhighlightedLabel}
              </PricingCard.Highlight>
            )}
          </div>
          {offer.isOnDemandTitle() || hideTotalPrice ? (
            <PriceOnDemand
              onDemandTitle={onDemandTitle}
              onDemandText={onDemandText}
            />
          ) : (
            <PricingCard.Price
              className={'price'}
              price={
                isFirstPayrollOffer || previousOffer
                  ? firstPayrollPriceTotal
                  : offerPriceTotal
              }
              previousPrice={
                (!hideTotalPrice &&
                  isFirstPayroll &&
                  firstPayrollOffer &&
                  offerPriceTotal) ||
                (previousOffer && offerPriceTotal) ||
                undefined
              }
              previousPricePerEmployee={
                (isFirstPayroll &&
                  firstPayrollOffer &&
                  offer.getPrice().getTotalPricePerEmployee()) ||
                (previousOffer && firstPayrollPrice?.getTotalPricePerEmployee())
              }
              pricePerEmployee={
                isFirstPayrollOffer || previousOffer
                  ? previousOffer
                    ? offer.getPrice().getTotalPricePerEmployee()
                    : firstPayrollPrice?.getTotalPricePerEmployee()
                  : price.getTotalPricePerEmployee()
              }
              abonnementPrice={
                isFirstPayrollOffer
                  ? firstPayrollPrice?.get('abonnementPrice')
                  : price.get('abonnementPrice')
              }
              abonnementPriceLabel={
                isFirstPayrollOffer
                  ? firstPayrollPrice?.get('abonnementPriceLabel')
                  : price.get('abonnementPriceLabel')
              }
              pricePerEmployeeLabel={
                isFirstPayrollOffer
                  ? firstPayrollPrice?.get('pricePerEmployeeLabel')
                  : price.get('pricePerEmployeeLabel')
              }
              installationLabel={price.get('installationLabel')}
              installationLineThrough={
                isFirstPayrollOffer || previousOffer
                  ? firstPayrollPrice?.get('installationLineThrough')
                  : price?.get('installationLineThrough')
              }
              template={template}
              currencyFirst={locale === 'en'}
              currency={currency}
              prefixTotal={prefixTotal}
              sufixTotal={sufixTotal}
              displayEmptyLines={displayEmptyLines}
              locale={locale}
            />
          )}
        </div>
        <div
          className={classNames(
            'flex flex-col flex-grow',
            addOns.length > 0 ? 'justify-between' : 'justify-start',
          )}
        >
          <div>
            {cta && (
              <PricingCard.CTA>
                <ButtonTracking
                  component={ButtonV2}
                  section="TablePrice"
                  index={index}
                  {...ctaCard}
                  variant={ borderHighlighted || isDisabled ? 'primary' : 'secondary'}
                  title={<span className="text-21">{ctaCard?.title}</span>}
                  className={classNames('w-full')}
                  labelTracking={ctaCard?.title}
                />
              </PricingCard.CTA>
            )}

            {engagementLabel && (
              <PricingCard.Engagement label={engagementLabel} />
            )}
          </div>
          <PricingCard.FeatureList>
            {features?.map((elt: any, key: number) => (
              <div
                className={classNames(
                  'FeatureList__element',
                  'flex flex-row items-center',
                )}
                key={key}
              >
                <PricingCard.Feature
                  name={
                    <>
                      {elt.name}
                      {!isEmptyDocument(elt?.tooltipText) && (
                        <Info
                          className={classNames(
                            'Info',
                            'inline-block',
                            'w-[15px] h-[15px] ml-8 mb-4',
                          )}
                        />
                      )}
                    </>
                  }
                  key={key}
                  tooltipTitle={elt.name}
                  tooltipText={
                    !isEmptyDocument(elt?.tooltipText) && (
                      <StructuredText
                        text={elt?.tooltipText}
                        renderParagraph={({ children, key: paragraphKey }) => (
                          <p
                            className={classNames(textCaption)}
                            key={paragraphKey}
                          >
                            {children}
                          </p>
                        )}
                      />
                    )
                  }
                />
              </div>
            ))}
            {featuresNotIncluded &&
              featuresNotIncluded?.map((elt: any, key: number) => (
                <PricingCard.Feature
                  name={
                    <>
                      {elt.name}
                      {!isEmptyDocument(elt?.tooltipText) && (
                        <Info
                          className={classNames(
                            'Info',
                            'inline-block',
                            'w-[15px] h-[15px] ml-8 mb-4',
                          )}
                        />
                      )}
                    </>
                  }
                  included={false}
                  key={key}
                  tooltipTitle={elt.name}
                  tooltipText={
                    !isEmptyDocument(elt?.tooltipText) && (
                      <StructuredText
                        text={elt?.tooltipText}
                        renderParagraph={({ children, key: paragraphKey }) => (
                          <p
                            className={classNames(textCaption)}
                            key={paragraphKey}
                          >
                            {children}
                          </p>
                        )}
                      />
                    )
                  }
                />
              ))}
          </PricingCard.FeatureList>
          <div
            className={classNames('Addon', 'flex flex-col h-full justify-end')}
          >
            {addOns?.map((elt: any, key: number) => {
              return (
                <Pricing.ExtraToggle
                  key={key}
                  name={`${elt?.props?.name.replaceAll(' ', '-')}-${index}`}
                  initialValue={elt?.props.initialValue}
                  isDisabled={isDisabled}
                  offerInclude={elt?.props.offerInclude}
                  includedLabel={elt?.props.includedLabel}
                >
                  <div className="Addon-Name text-neutral-301 font-semibold w-full">
                    <Popup
                      title={elt?.props?.name}
                      className="inline-block"
                      forceArrowPosition={'top'}
                      content={
                        !isEmptyDocument(elt?.props?.tooltipText) && (
                          <StructuredText
                            text={elt.props?.tooltipText}
                            renderParagraph={({
                              children,
                              key: paragraphKey,
                            }) => (
                              <p
                                className={classNames(textCaption)}
                                key={paragraphKey}
                              >
                                {children}
                              </p>
                            )}
                          />
                        )
                      }
                    >
                      {elt?.props?.name}
                      {!isEmptyDocument(elt?.props?.tooltipText) && (
                        <Info
                          className={classNames(
                            'Info',
                            'inline-block',
                            'w-[15px] h-[15px] ml-8 mb-[3px]',
                          )}
                        />
                      )}
                    </Popup>
                  </div>
                  <div
                    className={classNames(
                      'Addon-Price',
                      elt?.props.offerInclude && 'line-through',
                      'text-neutral-101 text-12 mb-24',
                    )}
                  >
                    {elt?.props.prefixPriceLabel}{' '}
                    {elt?.getDetailedPrice(language, currency)}
                  </div>
                </Pricing.ExtraToggle>
              );
            })}
          </div>
        </div>
      </div>
    </PricingCard>
  );
};
